@use './variables' as *;
@use './mixins' as *;

$display: (
  none: none,
  flex: flex,
  grid: grid,
  block: block,
  inline: inline,
  table-cell: table-cell,
);

@each $key, $value in $display {
  .d-#{$key} {
    display: $value;

    @if $key==grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

@each $limit, $width in $breakpoints {
  @each $key, $value in $display {
    @if $limit == sm {
      @include sm-screen {
        .d-#{$limit}-#{$key} {
          display: $value;

          @if $key==grid {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .d-#{$limit}-#{$key} {
          display: $value;

          @if $key==grid {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .d-#{$limit}-#{$key} {
          display: $value;

          @if $key==grid {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .d-#{$limit}-#{$key} {
          display: $value;

          @if $key==grid {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .d-#{$limit}-#{$key} {
          display: $value;

          @if $key==grid {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }
      }
    }
  }
}
