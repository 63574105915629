@use './variables' as *;
@use './mixins' as *;

.flex-1 {
  // flex: 1;
  flex-basis: 100%;
}

$aligns: center, baseline, start, end, stretch;

@each $position in $aligns {
  @if $position == start {
    .align-items-start {
      align-items: flex-start;
    }
  } @else if $position == end {
    .align-items-end {
      align-items: flex-end;
    }
  } @else {
    .align-items-#{$position} {
      align-items: $position;
    }
  }
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: end;
}

$directions: row, column, row-reverse, column-reverse;

@each $direction in $directions {
  .flex-#{$direction} {
    flex-direction: $direction;
  }
}

$wraps: nowrap, wrap, wrap-reverse;

@each $wrap in $wraps {
  .flex-#{$wrap} {
    flex-wrap: $wrap;
  }
}

@each $limit, $width in $breakpoints {
  @each $direction in $directions {
    @if $limit == sm {
      @include sm-screen {
        .flex-#{$limit}-#{$direction} {
          flex-direction: $direction;
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .flex-#{$limit}-#{$direction} {
          flex-direction: $direction;
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .flex-#{$limit}-#{$direction} {
          flex-direction: $direction;
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .flex-#{$limit}-#{$direction} {
          flex-direction: $direction;
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .flex-#{$limit}-#{$direction} {
          flex-direction: $direction;
        }
      }
    }
  }
}
