@use '@miq/styles/src/variables' as *;
@use '@miq/styles/src/mixins' as *;

.order-item-form .w-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: 100%;
  background-color: #25d366;
  border-color: #25d3655b;
  color: white;
}

.miq-shop-product-detail-view-grid-product h1 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.miq-shop-product-detail-view-grid-product .presale-icon {
  display: flex;
  span {
    margin-left: 6px;
    // background-color: var(--yellow-50);
  }
}
.miq-shop-product-detail-view-grid-product .p-price {
  margin-bottom: 0.5rem;
}

.miq-shop-product-detail-view-grid-product .p-price-sale,
.miq-shop-product-detail-view-grid-product .p-price-retail {
  font-size: 1.075rem;
}
