@use "@miq/styles/src/mixins" as *;

@mixin shared {
  padding: var(--sp-1, 0.5rem);
}

.view-card {
  &:not(.border-0) {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
  }

  .view-card-header {
    @include shared;

    &:not(.border-0) {
      border-bottom: 1px solid var(--border-color);
    }

    .view-card-title {
      font-size: 1rem;
    }
    .view-card-text {
      font-size: 0.8rem;
      color: var(--gray-600);

      @include break-word;
    }
  }

  .view-card-body {
    &:not(.p-0) {
      padding: var(--sp-1, 0.5rem);
    }
  }

  .view-card-footer {
    @include shared;

    &:not(.border-0) {
      border-top: 1px solid var(--border-color);
    }
  }
}
