.listitem {
  padding: 0.4em;
  margin-bottom: 6px;
}
.listitem_header {
  display: flex;
  .actions {
    --icon-size: 16px;
  }
}

.p_title {
  font-weight: 500;

  &.unpublished {
    color: var(--gray-600);
  }

  &.presale {
    color: var(--orange-600);
  }

  &.oos {
    color: var(--red-600);
  }
}
