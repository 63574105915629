@use './mixins' as *;
@use './variables' as *;

.miq-container {
  max-width: map-get($map: $breakpoints, $key: md);

  &.center {
    margin: 0 auto;
  }

  &-1024 {
    margin: 0;
    max-width: map-get($map: $breakpoints, $key: lg);
  }

  &-fluid {
    margin: 0;
    max-width: 100%;
  }
}

// .miq-container {
//   max-width: map-get($map: $breakpoints, $key: md);

//   &.center {
//     margin: 0 auto;
//   }

//   &-fluid {
//     margin: 0;
//     max-width: 100%;
//   }
// }

// @each $limit, $width in $breakpoints {
//   @if $limit == lg {
//     @include lg-screen {
//       .miq-container-lg {
//         max-width: map-get($map: $breakpoints, $key: md);
//       }
//     }
//   } @else if $limit == xl {
//     @include xl-screen {
//       .miq-container-xl {
//         max-width: map-get($map: $breakpoints, $key: md);
//       }
//     }
//   } @else if $limit == xxl {
//     @include xxl-screen {
//       .miq-container-xxl {
//         max-width: map-get($map: $breakpoints, $key: md);
//       }
//     }
//   }
// }
