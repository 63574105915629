@use '@miq/styles/src/variables' as *;
@use '@miq/styles/src/mixins' as *;

.p-imgs-hgallery {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .miq-imgs-gallery-horizontal-item {
    margin-right: 2px;
  }

  img {
    min-height: 60vh;
    max-height: 85vh;
  }
}

.p-price {
  display: flex;
  align-items: center;

  &:not(.sale) {
    font-weight: 500;
  }

  &.sale {
    .p-price-sale {
      font-weight: 500;
    }
  }
}

.miq-breadcrumbs {
  .item {
    &::after {
      content: '>';
      padding-right: 4px;
      padding-left: 4px;
    }

    &:last-child {
      &::after {
        content: '';
        padding: 0;
      }
    }
  }
}

.p-category-links {
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  white-space: nowrap;

  .item {
    display: inline-block;
    margin-right: 8px;
    vertical-align: bottom;
    // vertical-align: top;

    @include sm-down {
      text-align: center;

      img {
        border-radius: 50%;
      }
    }

    .info {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &::after {
      content: ' ';
    }
  }
}
