@use './variables' as *;
@use './mixins' as *;

$spacing: (
  1: var(--sp-1),
  2: var(--sp-2),
  3: var(--sp-3),
  4: var(--sp-4),
);

// MARGIN

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@each $level, $value in $spacing {
  .m-#{$level} {
    margin: $value;
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .m-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .m-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .m-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .m-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .m-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN TOP

@each $level, $value in $spacing {
  .mt-#{$level} {
    margin-top: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .mt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .mt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .mt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .mt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .mt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN BOTTOM

@each $level, $value in $spacing {
  .mb-#{$level} {
    margin-bottom: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .mb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .mb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .mb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .mb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .mb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN LEFT

@each $level, $value in $spacing {
  .ms-#{$level} {
    margin-left: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .ms-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .ms-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .ms-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .ms-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .ms-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN RIGHT

@each $level, $value in $spacing {
  .me-#{$level} {
    margin-right: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .me-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .me-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .me-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .me-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .me-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN RIGHT-LEFT

@each $level, $value in $spacing {
  .mx-#{$level} {
    margin-left: #{$value};
    margin-right: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .mx-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .mx-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .mx-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .mx-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .mx-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// MARGIN TOP-BOTTOM

@each $level, $value in $spacing {
  .my-#{$level} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .my-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .my-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .my-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .my-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .my-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING

@each $level, $value in $spacing {
  .p-#{$level} {
    padding: $value;
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .p-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .p-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .p-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .p-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .p-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING TOP

@each $level, $value in $spacing {
  .pt-#{$level} {
    padding-top: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .pt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .pt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .pt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .pt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .pt-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING BOTTOM

@each $level, $value in $spacing {
  .pb-#{$level} {
    padding-bottom: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .pb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .pb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .pb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .pb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .pb-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING LEFT

@each $level, $value in $spacing {
  .ps-#{$level} {
    padding-left: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .ps-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .ps-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .ps-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .ps-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .ps-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING RIGHT

@each $level, $value in $spacing {
  .pe-#{$level} {
    padding-right: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .pe-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .pe-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .pe-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .pe-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .pe-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING LEFT-RIGHT

@each $level, $value in $spacing {
  .px-#{$level} {
    padding-left: #{$value};
    padding-right: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .px-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .px-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .px-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .px-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .px-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

// PADDING TOP-BOTTOM

@each $level, $value in $spacing {
  .py-#{$level} {
    padding-top: #{$value};
    padding-bottom: #{$value};
  }
}

@each $limit, $width in $breakpoints {
  @each $level, $value in $spacing {
    @if $limit == sm {
      @include sm-screen {
        .py-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .py-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .py-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .py-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .py-#{$limit}-#{$level} {
          margin-left: #{$value};
        }
      }
    }
  }
}

//

.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.ps-0 {
  padding-left: 0;
}
.pe-0 {
  padding-right: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.p-0 {
  padding: 0;
}

//
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.ms-0 {
  margin-left: 0;
}
.me-0 {
  margin-right: 0;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.m-0 {
  margin: 0;
}
