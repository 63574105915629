@use '@miq/styles/src/mixins' as *;

@mixin input-mixin {
  font-size: var(--fs-input, 14px);
  line-height: 1.5;
  border: 1px solid var(--border-color, #ced4da);
  border-radius: var(--border-radius-input, 0.35rem);
  padding: var(--sp-input-y, 0.375rem) var(--sp-input-x, 0.75rem);
  appearance: none;
}

@mixin input-invalid {
  outline: none;
  border: 2px solid var(--red-500);
}

.miq-form {
  .miq-form-field {
    margin-bottom: var(--sp-1, 8px);

    label {
      font-weight: 500;
    }

    .miq-form-field-header {
      margin-bottom: var(--sp-0, 4px);
    }
    .miq-form-field-label {
      margin-bottom: 8px;
      font-weight: 600;
    }

    .miq-form-field-text {
      @include break-word;

      margin-bottom: 8px;
      white-space: pre-wrap;
      word-wrap: break-word;
      color: var(--gray-600, gray);
      font-size: var(--fs-sm, 0.8rem);
    }
  }
}

.miq-form-input {
  @include input-mixin;

  display: block;
  width: 100%;
  font-weight: 400;
  box-sizing: border-box;

  &.is-invalid {
    @include input-invalid;
  }
}

.miq-search-input-wrapper {
  position: relative;

  .icon-wrapper {
    --icon-size: 16px;

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-600);
    padding: var(--sp-1);

    svg {
      width: var(--icon-size, 16px);
      height: var(--icon-size, 16px);
    }
  }

  input {
    border: 1px solid var(--gray-100);
    background-color: var(--gray-100, white);
    padding-left: 36px;

    &:focus {
      background-color: white;
    }
  }
}

.miq-form-select {
  @include input-mixin;

  width: 100%;
  background-color: transparent;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

$min-height: 44px;
@mixin textareax-input {
  display: block;
  min-height: $min-height;
  // width: 100%; watch out for this as it causes overflow
  height: 100%;
  padding: var(--sp-input-y) var(--sp-input-x);
  font-size: var(--fs-input, 14px);
  font-weight: 400;
  font-family: var(--ff);
}

.miq-textareax {
  min-height: $min-height;
  position: relative;

  &.clear-focus {
    textarea {
      &:focus {
        box-shadow: none;
        border: 1px solid transparent;
        outline: none;
      }
    }
  }

  .miq-textareax-input-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    textarea {
      @include textareax-input;

      width: 100%;
      position: relative;

      overflow: hidden;
      resize: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-input);
    }
  }

  .miq-textareax-input-mirror {
    @include textareax-input;

    white-space: pre-wrap;
    word-wrap: break-word;
    visibility: hidden;
    border: 1px solid transparent;

    min-width: 200px;
  }
}

.miq-form-error {
  // grid-column-end: span 12;
  font-size: var(--fs-sm);
  color: var(--red-500);
}
