@use '@miq/styles/src/variables' as *;
@use '@miq/styles/src/mixins' as *;

.miq-img {
  width: 100%;
  height: auto;
}

.miq-imgs-gallery-horizontal {
  overflow: auto;
}

.miq-imgs-gallery-horizontal-inner {
  display: flex;
  align-items: center;

  @include md-screen {
    &.md-vertical {
      flex-direction: column;

      .miq-imgs-gallery-horizontal-item {
        margin-right: 0;
        margin-bottom: 6px;
      }
    }
  }
}

.miq-imgs-gallery-horizontal-item {
  display: inline-block;

  img {
    object-fit: contain;
    max-width: none;
    display: block;
    height: auto;
    max-height: 100vh;
    width: auto;
  }
}
