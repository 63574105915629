.miq-table {
  border-collapse: collapse;

  &.w-100 {
    width: 100%;
  }

  &.layout-fixed {
    table-layout: fixed;
  }

  &.layout-auto {
    table-layout: auto;
  }

  th,
  td {
    padding: var(--sp-1, 1rem);
  }

  th {
    position: relative;
  }

  thead tr {
    // position: relative;
    text-align: left;
  }

  thead th {
    top: 0;
    position: sticky;
    z-index: 1;
  }
  thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  tbody {
    th {
      left: 0;
      z-index: 1;
      position: sticky;
    }
  }
}

.miq-table.border {
  th:first-of-type {
  }
  th:last-of-type {
  }

  thead {
    // border-bottom: 1px solid var(--border-color);
  }

  td,
  tr {
  }

  &:first-child {
    // border-radius: var(--border-radius);
    // border-radius: var(--border-radius) var(--border-radius) 0 0;
  }

  &:last-child {
    // border-radius: 0 0 var(--border-radius) var(--border-radius);
  }
}
