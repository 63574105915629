@use './variables' as *;
@use './mixins' as *;

.gap-1 {
  gap: var(--sp-0, 0.25em);
}

.gap-2 {
  gap: var(--sp-1, 0.5em);
}

.gap-3 {
  gap: var(--sp-2, 0.75em);
}

.gap-4 {
  gap: var(--sp-4, 1em);
}

@for $i from 1 through $grid-cols - 1 {
  .grid-offset-#{$i} {
    grid-column-start: $i + 1;
  }
}

@each $limit, $width in $breakpoints {
  @for $i from 1 through $grid-cols - 1 {
    @if $limit == xs {
    } @else if $limit == sm {
      @include sm-screen {
        .grid-offset-#{$limit}-#{$i} {
          grid-column-start: $i + 1;
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .grid-offset-#{$limit}-#{$i} {
          grid-column-start: $i + 1;
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .grid-offset-#{$limit}-#{$i} {
          grid-column-start: $i + 1;
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .grid-offset-#{$limit}-#{$i} {
          grid-column-start: $i + 1;
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .grid-offset-#{$limit}-#{$i} {
          grid-column-start: $i + 1;
        }
      }
    }
  }
}

@for $i from 1 through $grid-cols {
  .grid-#{$i} {
    grid-template-columns: repeat($i, minmax(0, 1fr));
  }
  .span-#{$i} {
    grid-column-end: span #{$i};
  }
}

@each $limit, $width in $breakpoints {
  @for $i from 1 through $grid-cols {
    @if $limit == sm {
      @include sm-screen {
        .grid-#{$limit}-#{$i} {
          grid-template-columns: repeat($i, minmax(0, 1fr));
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .grid-#{$limit}-#{$i} {
          grid-template-columns: repeat($i, minmax(0, 1fr));
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .grid-#{$limit}-#{$i} {
          grid-template-columns: repeat($i, minmax(0, 1fr));
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .grid-#{$limit}-#{$i} {
          grid-template-columns: repeat($i, minmax(0, 1fr));
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .grid-#{$limit}-#{$i} {
          grid-template-columns: repeat($i, minmax(0, 1fr));
        }
      }
    }
  }
}

@each $limit, $width in $breakpoints {
  @for $i from 1 through $grid-cols {
    @if $limit == sm {
      @include sm-screen {
        .span-#{$limit}-#{$i} {
          grid-column-end: span #{$i};
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .span-#{$limit}-#{$i} {
          grid-column-end: span #{$i};
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .span-#{$limit}-#{$i} {
          grid-column-end: span #{$i};
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .span-#{$limit}-#{$i} {
          grid-column-end: span #{$i};
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .span-#{$limit}-#{$i} {
          grid-column-end: span #{$i};
        }
      }
    }
  }
}

// grid-template-columns: repeat(12, minmax(calc((100 / 12) * 1%), 100%));
