@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200..900&family=Roboto:wght@400;900&display=swap');

$fs: (
  xs: var(--fs-xs),
  sm: var(--fs-sm),
  md: var(--fs-md),
  lg: var(--fs-lg),
  xl: var(--fs-xl),
  xxl: var(--fs-xxl),
);

@each $key, $val in $fs {
  .text-#{$key} {
    font-size: $val;
  }
}

// FONT WEIGHT

$fw: (
  lighter: lighter,
  normal: normal,
  bold: bold,
  bolder: bolder,
);

@each $key, $val in $fw {
  .fw-#{$key} {
    font-weight: $val;
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
}

// TEXT DECORATION

.text-underline {
  text-decoration: underline;
}
.text-line-through {
  text-decoration: line-through;
}
.text-overline {
  text-decoration: overline;
}

// TEXT COLOR

.text-muted {
  color: var(--gray-600, gray);
}

.text-center {
  text-align: center;
}

.text-danger {
  color: var(--red-600, red);
}
