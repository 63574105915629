@use 'variables' as *;

@each $name, $values in $colors {
  @each $level, $color in $values {
    .bg-#{$name}-#{$level} {
      background-color: #{$color};
    }

    .color-#{$name}-#{$level} {
      color: #{$color};
    }

    .border-color-#{$name}-#{$level} {
      border-color: #{$color};
    }
  }
}
