@use '@miq/styles/src/variables' as *;
@use '@miq/styles/src/mixins' as *;

.miq-staff-layout {
  --icon-size: 24px;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  max-height: 100%;
  height: 100%;
  background-color: #f7f8fc;

  .miq-staff-layout-main {
    flex-grow: 1;
    position: relative;
    overflow-y: auto;
    padding-bottom: 90px;

    .miq-container-fluid {
      // flex-grow: 1;
      position: relative;
      padding: 0 0.5em;
    }
  }

  .miq-staff-layout-sidebar {
    --fs-xxl: 1.5em;
    --icon-size: 20px;

    min-width: 0;
    display: flex;
    flex: 0 0 140px;
    position: relative;
    padding-bottom: 1em;
    flex-direction: column;
    transform: translateX(0);

    @include lg-down {
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 260px;
      transform: translate3d(-260px, 0, 0);
      transition: transform 0.4s cubic-bezier(0.1, 0.7, 0.1, 1);
    }
  }

  .miq-staff-layout-sidebar-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .miq-staff-layout-sidebar-body-content,
    .miq-staff-layout-sidebar-body-footer {
      display: flex;
      flex-direction: column;

      .miq-staff-nav-link {
        padding: 0.5em;
        margin-right: 0.5em;
        display: flex;
        align-items: center;
        position: relative;
        color: black;
        border-left: 0.3em solid transparent;

        &.active {
          font-weight: 600;
          border-left: 0.3em solid var(--blue-600);
          background-color: var(--gray-200);
          border-top-right-radius: var(--border-radius, 4em);
          border-bottom-right-radius: var(--border-radius, 4em);
          // color: var(--blue-600);

          svg {
            // fill: var(--blue-600);
          }
        }

        &:hover {
          background-color: var(--blue-50);
          // border-left: 3px solid var(--blue-100);
        }

        .label {
          font-size: 1em;
          padding-left: 28px;
          display: block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        svg {
          position: absolute;
          line-height: 1.5;
        }
      }
    }
  }

  .miq-staff-layout-mobile-nav {
    display: none;

    .miq-staff-nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        display: block;
      }

      .label {
        font-weight: 700;
      }
    }

    @include lg-down {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9999;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}
