.sales-staff {
  .cart-items-preview {
    grid-auto-columns: min-content;
    gap: 3px;
    min-height: 48px;
    margin-bottom: 0;
    padding-bottom: 0;
    overflow-y: hidden;
  }
}

.sales-staff .sales-index-view .miq-view-section {
  margin-bottom: 12px;
}
