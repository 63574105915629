@use '@miq/styles/src/variables' as *;
@use '@miq/styles/src/mixins' as *;

$pd: 6px;

.lib-createview {
  height: 100vh;
  background-color: transparent;
  overflow-y: hidden;

  .logo-wrapper {
    // display: none;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 96px;
      border-radius: 50%;
      height: auto;
      object-fit: contain;
      aspect-ratio: 1/1;
    }
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lib-btn {
      border: 0;
      background-color: transparent;
      padding: 12px;
      margin-left: 6px;
      margin-right: 6px;

      svg {
        width: 24px;
        height: 24px;
        color: black;
      }
    }
  }

  .body {
    padding-top: 40px;
    padding-bottom: 30px;
    background-color: #f7f8fc;
    margin-left: $pd;
    margin-right: $pd;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }

  .categories {
    margin: $pd;

    .cat-grid {
      padding-bottom: 0;
      grid-auto-columns: max-content;
      gap: 6px;

      .cat {
        // padding: 0.4rem 0.75rem;

        img {
          max-width: 150px;
          object-fit: contain;
          border-radius: 24px;
        }
      }
    }
  }

  .btn-main {
    margin-right: 6px;
    min-width: 250px;
    text-align: center;
  }

  .actions-main {
    margin-top: 12px;
  }

  .auth {
    span {
      margin-right: 3px;
    }

    svg {
      color: var(--red-600);
      fill: var(--red-600);
      vertical-align: -6px;
    }
  }
}

.lib-createview .lib-createview-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // align-items: center;
}
