@use '@miq/styles/src/mixins' as *;

.miq-view {
  --fs-xl: 1.725rem;
  --fs-lg: 1.5rem;
  --fs-md: 1.25rem;

  --icon-size: 20px;
}

.miq-view-header-wrapper {
  margin-bottom: var(--sp-1);
}

.miq-view-header {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.miq-view-header-text {
  @include break-word;

  color: var(--gray-600, gray);
  font-size: var(--fs-sm, 0.8rem);
}

.miq-view-header-title-text {
  font-size: 1.2em;
}

//

.miq-view-section {
  margin-bottom: var(--sp-1, 2rem);
}
.miq-view-section.border {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  .miq-view-section-header {
    margin-bottom: 0;
    padding: var(--sp-1, 0.5rem);
  }

  .miq-view-section-main {
    border-top: 1px solid var(--border-color);
    padding: var(--sp-1, 0.5rem);
  }

  .miq-view-section-footer {
    border-top: 1px solid var(--border-color);
    padding: var(--sp-1, 0.5rem);
  }
}

.miq-view-section-header {
  margin-bottom: var(--sp-2, 1rem);
}

.miq-view-section-header-title {
  font-size: 1rem;
  font-weight: 400;
  font-family: var(--ff);
}

.miq-view-section-header-text {
  @include break-word;

  color: var(--gray-600, gray);
  font-size: var(--fs-sm, 0.8rem);
}
