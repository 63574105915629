@use 'variables' as *;

:root {
  // SPACES
  @each $level, $value in $spaces {
    --sp-#{$level}: #{$value};
  }

  --sp-input-x: 0.75em;
  --sp-input-y: 0.375em;

  // FONT SIZES

  // --fs: 1em;
  --fs: 14px;
  --fs-xs: 0.512em;
  --fs-sm: 0.8em;
  --fs-md: 1.25em;
  --fs-lg: 1.563em;
  --fs-xl: 1.953em;
  --fs-xxl: 2.441em;
  --fs-xxxl: 3.052em;

  --fs-nav: 0.65em;
  --fs-input: 14px;

  // FONT TYPES

  --ff: 'Poppins', sans-serif;
  // --ff: "Roboto", sans-serif;
  --ff-heading: 'Roboto', sans-serif;
  // --ff-heading: "Montserrat", sans-serif;

  // BORDERS

  --border-color: #{$border-color};

  --border-radius: 6px;
  --border-radius-md: 12px;
  --border-radius-lg: 24px;

  --border-radius-input: 0.35em;

  // IMAGES

  --img-thumb-size-xs: 32px;
  --img-thumb-size-sm: 48px;
  --img-thumb-size-md: 64px;
  --img-thumb-size-lg: 96px;
  --img-thumb-size-xl: 128px;
  --img-thumb-size-xxl: 256px;

  // ICONS

  --icon-size: #{$icon-size};

  // COLORS

  @each $name, $values in $colors {
    @each $level, $color in $values {
      --#{$name}-#{$level}: #{$color};
    }
  }
}
