@use '@miq/styles/src/mixins' as *;

.miq-sticky-footer-layout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  max-height: 100%;
  height: 100%;
}

.miq-sticky-footer-view {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  height: 100%;
  width: 100%;
}

.miq-sticky-footer-main {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}

.miq-hgrid-layout {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - 50px * 2);

  @include md-down {
    // grid-auto-columns: min-content;
    grid-auto-columns: calc(100vw - 50px * 2);
  }

  overflow-x: scroll;
  position: relative;

  scrollbar-width: 0.5px;
  -ms-overflow-style: 0.5px;

  &::-webkit-scrollbar {
    width: 0.5px;
  }

  &.no-scroll-bar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & > div {
    left: 0;
  }
}
