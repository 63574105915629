@use './root';
@use './display';
@use './grid';
@use './container';
@use './colors';
@use './variables' as *;
@use './typography';
@use './margin';
@use './flexbox';

@use './mixins' as *;

.round {
  border-radius: 50%;
}

.rounded {
  border-radius: var(--border-radius, 6px);
}

// BORDERS

.border-1 {
  border: 1px solid var(--border-color);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.border-0 {
  border: 0;
}

// WIDTH

$widths: (
  10: 10,
  15: 15,
  25: 25,
  35: 35,
  40: 40,
  50: 50,
  65: 65,
  75: 75,
  100: 100,
);

@each $width, $value in $widths {
  .w-#{$width} {
    width: $value * 1% !important;
  }
  .vw-#{$width} {
    width: #{$value}vw;
  }
  .h-#{$width} {
    height: $value * 1% !important;
  }
  .vh-#{$width} {
    height: #{$value}vh;
  }
  //
  .min-w-#{$width} {
    min-width: $value * 1% !important;
  }
  .max-w-#{$width} {
    max-width: $value * 1% !important;
  }
}

@each $limit, $width in $breakpoints {
  @each $width, $value in $widths {
    @if $limit == sm {
      @include sm-screen {
        .w-#{$limit}-#{$value} {
          width: $value * 1% !important;
        }
      }
    } @else if $limit == md {
      @include md-screen {
        .w-#{$limit}-#{$value} {
          width: $value * 1% !important;
        }
      }
    } @else if $limit == lg {
      @include lg-screen {
        .w-#{$limit}-#{$value} {
          width: $value * 1% !important;
        }
      }
    } @else if $limit == xl {
      @include xl-screen {
        .w-#{$limit}-#{$value} {
          width: $value * 1% !important;
        }
      }
    } @else if $limit == xxl {
      @include xxl-screen {
        .w-#{$limit}-#{$value} {
          width: $value * 1% !important;
        }
      }
    }
  }
}

// COLOR

.bg-white {
  background-color: white;
}

// RESET

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  // margin: 0 0 var(--header-height) 0;
  font-family: var(--ff);
  font-size: var(--fs);
  // background-color: var(--body-color);
  // color: var(--text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // color: var(--title-color);
  // font-weight: var(--font-semi-bold);
  font-family: var(--ff-heading);
}

h1 {
  font-size: var(--fs-xxl);
}
h2 {
  font-size: var(--fs-xl);
}
h3 {
  font-size: var(--fs-lg);
}
h4 {
  font-size: var(--fs-md);
}
h5 {
  font-size: var(--fs-sm);
}
h6 {
  font-size: var(--fs-sx);
}

ul {
  list-style: none;
}

a {
  color: var(--gray-900);
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
