@use '@miq/styles/src/mixins' as *;

.miq-staff-view {
  --fs-xl: 1.725em;
  --fs-lg: 1.5em;
  --fs-md: 1.25em;
  --fs-sm: 0.9em;
  --icon-size: 20px;

  .miq-staff-view-header-wrapper {
    margin-bottom: var(--sp-1);

    .miq-staff-view-header {
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .miq-staff-view-header-title-text {
        font-size: 1.2em;
      }
    }

    .miq-staff-view-header-text {
      @include break-word;

      color: var(--gray-600, gray);
      font-size: var(--fs-sm, 0.8em);
    }
  }

  .miq-staff-view-main {
    --border-radius: 6px;

    &:not(.p-0) {
      padding: 6px;
    }

    &:not(.no-bg) {
      background-color: white;
    }
  }
}

.miq-staff-view-main .view-navlinks {
  padding-top: 3px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;

  .view-navlink {
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 0.75rem;
    border-radius: var(--border-radius);
    border: 1px solid transparent;
    background-color: var(--gray-100, transparent);

    &.active {
      color: var(--blue-600, blue);
      background-color: var(--blue-50, transparent);
    }
  }
}

.miq-grid-view {
  display: grid;
  // height: 100vh;

  &.scrollable {
    @include lg-screen {
      // position: fixed;
      // position: absolute;
    }
  }
}

@mixin col {
  @include lg-screen {
    overflow-y: auto;
    // height: 50px;
    // height: 100vh;
  }
}

.miq-grid-view-aside {
  @include col;
}

.miq-grid-view-main {
  @include col;
}
