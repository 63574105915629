@use '@miq/styles/src/mixins' as *;

.close-template {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .close-template-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;

    background-color: transparent;

    color: white;
    text-shadow: 1px 1px 2px #000000;

    .close-template-logo {
      max-width: 100px;
      max-height: 100px;
      // overflow: hidden;

      .miq-loader {
        margin-bottom: 1rem;
        .sk-cube-grid {
          margin: 0;
        }
      }
    }

    .close-template-text {
      @include break-word;

      font-family: var(--ff);
    }
  }

  .close-template-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    object-fit: cover;
  }
}
