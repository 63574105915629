// @use '@miq/styles/src/variables' as *;

@mixin position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.miq-modal {
  @include position-absolute;
  position: fixed;

  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  z-index: 10000;

  .miq-modal-inner {
    &:not(.fs) {
      margin: 24px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    max-width: 100%;
    width: 100%;
    position: relative;
    z-index: 100;

    .miq-modal-inner-content {
      max-height: 100%;
      overflow: auto;
      margin: 0 auto;

      background-color: var(--gray-50, white);

      &.xs {
        max-width: 320px;
      }

      &.sm {
        max-width: 640px;
      }

      &.md {
        max-width: 768px;
      }

      &.lg {
        max-width: 1024px;
      }

      &.xl {
        max-width: 1280px;
      }

      &.fs {
        width: 100%;
        height: 100%;
      }

      &:not(.fs) {
        border-radius: var(--border-radius, 8px);
        // box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%), 0 12.5px 10px rgb(0 0 0 / 4%),
        //   0 22.3px 17.9px rgb(0 0 0 / 4%), 0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);
      }
    }

    .miq-modal-inner-header {
      padding: var(--sp-0, 4px);
      position: sticky;
      top: 0;
    }

    .miq-modal-inner-footer {
      padding: var(--sp-0, 4px);
      position: sticky;
      bottom: 0;
    }

    .miq-modal-inner-body {
      flex-grow: 1;
      overflow: auto;
    }
  }

  .miq-modal-background {
    @include position-absolute;

    z-index: 50;
    background: #15171a;
    opacity: 0.5;
    height: 100vh;
  }
}
