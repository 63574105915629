@mixin btn {
  // background-color: transparent;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.btn {
  @include btn;

  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  text-decoration: none;
  color: var(--gray-900, black);
  padding: 0.25rem 0.5rem;

  &.btn-md {
    padding: 0.5rem 0.75rem;
  }

  &:hover {
    background-color: var(--gray-100);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-100);
  }
}

.btn-link {
  @include btn;
}

.btn-square {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-round {
  min-width: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 8px;
}

.btn-primary {
  color: white;
  border: 1px solid var(--blue-700);
  background-color: var(--blue-600);

  &:hover {
    background-color: transparent;
    color: var(--blue-600);
    border: 1px solid var(--blue-600);
  }
}

.btn-primary-2 {
  background-color: transparent;
  color: var(--blue-600);
  border: 1px solid var(--blue-600);

  &:hover {
    color: white;
    border: 1px solid var(--blue-700);
    background-color: var(--blue-600);
  }
}

.btn-primary-3 {
  background-color: var(--blue-50, transparent);
  color: var(--blue-600);
  border: 1px solid var(--blue-50);

  &:hover {
    border: 1px solid var(--blue-600);
    background-color: var(--blue-50, transparent);
  }
}

.btn-secondary {
  color: white;
  border: 1px solid var(--gray-700);
  background-color: var(--gray-600);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: transparent;
    color: var(--gray-600);
    border: 1px solid var(--gray-600);
  }
}

.btn-secondary-2 {
  background-color: transparent;
  color: var(--gray-600);
  border: 1px solid var(--gray-600);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);

  &.active {
    color: white;
    border: 1px solid var(--gray-700);
    background-color: var(--gray-600);
  }

  &:hover {
    color: white;
    border: 1px solid var(--gray-700);
    background-color: var(--gray-600);
  }
}

.btn-secondary-3 {
  color: var(--gray-600);
  border: 1px solid var(--gray-100);
  background-color: var(--gray-100, transparent);

  &:hover {
    border: 1px solid var(--gray-600);
    background-color: var(--gray-100, transparent);
  }

  &.active {
    border-color: transparent;
    color: white;
    background-color: var(--blue-600);
  }
}

.btn-secondary-4 {
  color: var(--gray-600, black);
  border-color: transparent;

  &:hover {
    color: var(--gray-800, black);
  }
}

.btn-danger {
  color: white;
  border: 1px solid var(--red-700);
  background-color: var(--red-600);

  &:hover {
    background-color: transparent;
    color: var(--red-600);
    border: 1px solid var(--red-600);
  }
}

.btn-danger-2 {
  background-color: transparent;
  color: var(--red-600);
  border: 1px solid var(--red-600);

  &:hover {
    color: white;
    border: 1px solid var(--red-700);
    background-color: var(--red-600);
  }
}
.btn-danger-3 {
  background-color: var(--red-50, transparent);
  color: var(--red-600);
  border: 1px solid var(--red-50);

  &:hover {
    border: 1px solid var(--red-600);
    background-color: var(--red-50, transparent);
  }
}
